<template>
  <div v-if="center && hasData">
    <div
      class="tw-flex tw-flex-col tw-bg-[#7EC161] tw-rounded-md tw-border tw-border-[#7EC161]"
    >
      <div class="tw-flex tw-justify-center tw-pt-1">
        <img
          class="tw-z-10 tw-w-[46px] tw-h-[46px]"
          src="./finger-print-logo.svg"
          alt="Finger Print Logo"
        />
      </div>

      <div
        class="tw-relative tw-flex tw-flex-col -tw-mt-4 tw-bg-white tw-rounded-md tw-pl-3.5 tw-pr-8 tw-pt-8 tw-pb-8"
      >
        <img
          @mousedown="helpModalState = true"
          class="tw-cursor-pointer tw-absolute tw-top-4 tw-right-4 tw-w-[16px] tw-h-[16px]"
          src="./help-icon.svg"
          alt="Help"
        />
        <span class="tw-text-[11px]"
          >Los datos que te ofrecemos en este apartado se corresponden con tus
          emisiones de Alcance 3 por el transporte y distribución de los pedidos
          realizados a Maripol & Royal y enviados a tus establecimientos.</span
        >
      </div>

      <div
        class="tw-relative tw-flex tw-flex-col -tw-mt-6 -tw-mb-[1px] -tw-ml-[1px] -tw-mr-[1px] tw-bg-white tw-rounded-t-md tw-px-3.5 tw-pt-3 tw-border tw-border-[#7EC161]"
      >
        <div class="tw-text-[13px] tw-text-[#646464] tw-mb-1">
          Huella de carbono de este pedido
        </div>
        <div
          class="tw-text-[16px] tw-text-[#363835] tw-mb-4 tw-font-bold tw-pl-3.5"
        >
          {{ data.co2e }} kg CO<sub>2</sub>
        </div>

        <div class="tw-text-[13px] tw-text-[#646464] tw-mb-1">
          Huella de Carbono acumulado año
        </div>
        <div
          class="tw-text-[16px] tw-text-[#363835] tw-mb-4 tw-font-bold tw-pl-3.5"
        >
          {{ data.co2e_current_year }} kg CO<sub>2</sub>
        </div>

        <div class="tw-text-[13px] tw-text-[#646464] tw-mb-1">
          Proyección a final de año considerando frecuencia y pedido medio
        </div>
        <div
          class="tw-text-[16px] tw-text-[#363835] tw-mb-4 tw-font-bold tw-pl-3.5"
        >
          {{ Math.round(data.co2e_year_projection) }} kg CO<sub>2</sub>
        </div>

        <div class="tw-text-[13px] tw-text-[#646464] tw-mb-1">
          Para absorber el CO2 producido se necesitan: *
        </div>
        <div
          class="tw-text-[16px] tw-text-[#17702C] tw-mb-4 tw-font-bold tw-pl-3.5"
        >
          {{ Math.round(data.trees) }} árboles
        </div>
      </div>

      <img
        v-if="data['show_simulator']"
        @mousedown="modalState = true"
        class="tw-cursor-pointer tw-z-0 tw-mt-[-55px]"
        src="./footer.svg"
        alt=""
      />

      <img
        v-else
        class="tw-z-0 tw-mt-[-55px]"
        src="./footer-nolink.svg"
        alt=""
      />
    </div>

    <div class="tw-text-[11px] tw-px-1 tw-my-4">
      <div class="tw-mb-4">
        Cálculos realizados según la norma DIN 16258 y la guía
        <a
          href="https://www.clecat.org/media/CLECAT_Guide_on_Calculating_GHG_emissions_for_freight_forwarding_and_logistics_services.pdf"
          target="_blank"
          class="tw-underline tw-text-[#00ADFC] tw-cursor-pointer"
          >CECLAT</a
        >
        (European Association for Forwarding, Transport, Logistics and Customs
        Services).
      </div>

      <div>
        <calc-annotation></calc-annotation>
      </div>
    </div>

    <div class="tw-flex tw-justify-center" style="padding: 0 28px">
      <gov-logo></gov-logo>
    </div>

    <modal :show.sync="modalState"></modal>
    <help :show.sync="helpModalState"></help>
  </div>
</template>

<script>
import Modal from "./Modal.vue";
import CalcAnnotation from "@/components/finger-print/CalcAnnotation";
import Help from "@/components/finger-print-help/Modal.vue";
import Stores from "@/store/index";
import API from "./api";
import GovLogo from "@/components/finger-print/GovLogo";

export default {
  name: "FingerPrintSideBox",
  components: {
    GovLogo,
    CalcAnnotation,
    Modal,
    Help,
  },
  data() {
    return {
      modalState: false,
      helpModalState: false,
      hasData: false,
      data: {
        co2e: "0",
        co2e_current_year: "0",
        co2e_year_projection: "0",
        trees: 0,
      },
    };
  },
  computed: {
    center: () => Stores.getters.center,
  },
  watch: {
    center: {
      async handler(center) {
        if (center) {
          const request = await API.getSideBoxInfo();

          if (request.status === 200) {
            this.data = request.data;
            this.hasData = this.data.data;
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>

<template lang="pug">
    div( :class="{'tw-right-[-114px]': hover || slim, 'tw-right-0': !hover && !slim, 'tw-bg-[#ccc]': index == null, 'tw-bg-[#FC7C4C]': index !== null && index < 5, 'tw-bg-[#CACB59]': index >= 5 && index < 8, 'tw-bg-[#7CBD56]': index > 8, 'hover:tw-right-0': hover}" class="tw-text-[10px] tw-top-0 tw-rounded-bl-[8px] tw-transition-all tw-absolute tw-text-white tw-flex tw-p-1 tw-cursor-pointer")
        span(v-if="index > 0 || index !== null" class="tw-pr-1") {{ index | formatNumber(2,2) }}
        span(v-else class="tw-pr-1") N.I.
        svg(id="Componente_58_1" data-name="Componente 58 – 1" xmlns="http://www.w3.org/2000/svg" width="18.883" height="10.938" viewBox="0 0 18.883 10.938")
            path(id="Trazado_1247" data-name="Trazado 1247" d="M14.832,19.557a1.088,1.088,0,0,1-1.077-1.1V11.651c0-.392-.15-3.838-4.309-3.838H6.371c.267,1.645,1.174,4.524,4.242,4.524h1.144a1.1,1.1,0,0,1,0,2.193H10.613c-5.067,0-6.472-5.1-6.509-7.8a1.108,1.108,0,0,1,.31-.785,1.072,1.072,0,0,1,.767-.327H9.445a6.35,6.35,0,0,1,4.736,1.768,6.577,6.577,0,0,1,1.728,4.269v6.8A1.087,1.087,0,0,1,14.832,19.557Z" transform="translate(-4.104 -5.619)" fill="#fff")
            path(id="Trazado_1248" data-name="Trazado 1248" d="M23.926,11.625H17.91a1.1,1.1,0,0,1,0-2.193h6.016a1.1,1.1,0,0,1,0,2.193Z" transform="translate(-3.12 -5.25)" fill="#fff")
            path(id="Trazado_1249" data-name="Trazado 1249" d="M22.354,15.062H17.91a1.1,1.1,0,0,1,0-2.193h4.444a1.1,1.1,0,0,1,0,2.193Z" transform="translate(-3.12 -4.918)" fill="#fff")
            path(id="Trazado_1250" data-name="Trazado 1250" d="M20.514,18.5h-2.6a1.1,1.1,0,0,1,0-2.193h2.6a1.1,1.1,0,0,1,0,2.193Z" transform="translate(-3.12 -4.585)" fill="#fff")
        span(class="tw-pl-1 tw-pr-1" @click="toGoProduct") VER ALTERNATIVAS
        div(class="tw-bg-white tw-rounded-full tw-text-black tw-w-4 tw-h-4 tw-text-center" @click="$store.commit('MODAL_INFO_SOSTE_VIEW', true)") ?
   
</template>

<script>
export default {
    props: {
        index: {
            required: true
        },
        hover: {
            type: Boolean,
            required: false,
            default: true
        },
        slim: {
            type: Boolean,
            required: false,
            default: false
        },
        productId: {
            type: Number,
            required: true
        }
    },
    methods: {
        toGoProduct () {
            let nameRoute = 'productSingle'
            if (this.$route.path.indexOf('catalogo-publico') > -1) {
                nameRoute = 'productSinglePublic'
            }
            this.$router.push({'name': nameRoute, 'params': {'id': this.productId.toString()}, 'hash': '#infoSoste'})
        },
    }
}
</script>

<style scoped>
</style>
import { render, staticRenderFns } from "./PedidosHome.vue?vue&type=template&id=ac515bd6&scoped=true&lang=pug&"
import script from "./PedidosHome.vue?vue&type=script&lang=js&"
export * from "./PedidosHome.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac515bd6",
  null
  
)

export default component.exports
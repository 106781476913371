import { render, staticRenderFns } from "./OfertasList.vue?vue&type=template&id=88c141e6&scoped=true&lang=pug&"
import script from "./OfertasList.vue?vue&type=script&lang=js&"
export * from "./OfertasList.vue?vue&type=script&lang=js&"
import style0 from "./OfertasList.vue?vue&type=style&index=0&id=88c141e6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88c141e6",
  null
  
)

export default component.exports
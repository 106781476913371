<template lang="pug">
  .banner-slider

    .container(v-if="$route.name !== 'productSingle' && typeEcom === 'prisma'" :class="{'noShow': (catActive && catActive.level != 0) || query}")
      .row
        .col-sm-12.prisma
          //.adplugg-tag(data-adplugg-zone="slide_Prisma")
          #bannerCarousel(class="carousel slide carousel-fade" data-ride="carousel")
            .carousel-inner
              .item.active
                img(:src="img.bannerPrisma1" class="d-block w-100" width="1200")
              .item
                img(:src="img.bannerPrisma2" class="d-block w-100" width="1200")
              .item
                img(:src="img.bannerPrisma3" class="d-block w-100" width="1200")

    .container(v-if="$route.name !== 'productSingle' && typeEcom === 'maripol'" :class="{'noShow': (catActive && catActive.level != 0) || query}")
      .row
        .col-sm-12
          .adplugg-tag(data-adplugg-zone="slide_Maripol")

    .container(v-if="$route.name !== 'productSingle' && typeEcom === 'pedrosa'" :class="{'noShow': (catActive && catActive.level != 0) || query}")
      .row
        .col-sm-12
          .adplugg-tag(data-adplugg-zone="slide_Pedrosa")

    .container(v-if="$route.name !== 'productSingle' && typeEcom === 'orbishigiene'" :class="{'noShow': (catActive && catActive.level != 0) || query}")
      .row
        .col-sm-12
          #bannerCarousel(class="carousel slide" data-ride="carousel")
            .carousel-inner
              .item.active
                a(href="#" @click="setCategory('337-252')")
                  img(:src="img.slider1" class="d-block w-100" width="1200")
              .item
                a(href="#" @click="setCategory('337-24-25')")
                  img(:src="img.slider2" class="d-block w-100" width="1200")
              .item
                a(href="#" @click="setCategory('337-139')")
                  img(:src="img.slider3" class="d-block w-100" width="1200")

            // Left and right controls
            a( class="left carousel-control" href="#bannerCarousel" data-slide="prev")
              img(:src="img.flecha" width="20")
            a( class="right carousel-control" href="#bannerCarousel" data-slide="next")
              img(:src="img.flecha" width="20")
</template>

<script>
export default {
  name: 'BannerSlider',
  props: ['catActive', 'query'],
  data () {
    return {
      img: {
        slider1: require('@/assets/img/banner/banner_home_1200x200.jpg'),
        slider2: require('@/assets/img/banner/banner_home_1200x200_slide2.jpg'),
        slider3: require('@/assets/img/banner/banner_home_1200x200_slide3.jpg'),
        flecha: require('@/assets/img/icos/flecha-01.svg'),
        bannerPrisma1: require('@/assets/img/banner/banner_prisma_1.png'),
        bannerPrisma2: require('@/assets/img/banner/banner_prisma_2.png'),
        bannerPrisma3: require('@/assets/img/banner/banner_prisma_3.png')
      }
    }
  },
  computed: {
    typeEcom () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  methods: {
    setCategory (v) {
      this.$emit('toSetCategory', v)
    }
  }
}
</script>

<style lang="scss" scoped>
  .banner-slider{
    & > .container{
      // margin-top: 16px;
      // background-color: rgba(#EFEFEF, .35);
      overflow: hidden;
      @include transition(.6);
      height: 200px;

      &.noShow{
        height: 0;
        // opacity: 0;
      }
    }
  }

  .carousel-control{
    width: 60px;
    height: 60px;
    line-height: 60px;
    margin: auto;
    @include roundAll100(50%);
    background-color: rgba(#000, .3);

    &.left{
      left: 30px;
    }

    &.right{
      right: 30px;
      line-height: 54px;
      transform: rotateZ(180deg);
    }
  }

  /* CSS used here will be applied after bootstrap.css */  
.prisma .carousel-inner > .item > img, .prisma .carousel-inner > .item > a > img {
        display: block;
        height: auto;
        width: 100%;
        line-height: 1;

    }

.prisma .carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

.prisma .carousel-fade .carousel-inner .active {
  opacity: 1;
}

.prisma .carousel-fade .carousel-inner .active.left,
.prisma .carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.prisma .carousel-fade .carousel-inner .next.left,
.prisma .carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.prisma .carousel-fade .carousel-control {
  z-index: 2;
}


@media all and (transform-3d), (-webkit-transform-3d) {
    .prisma .carousel-fade .carousel-inner > .item.next,
    .prisma .carousel-fade .carousel-inner > .item.active.right {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .prisma .carousel-fade .carousel-inner > .item.prev,
    .prisma .carousel-fade .carousel-inner > .item.active.left {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .prisma .carousel-fade .carousel-inner > .item.next.left,
    .prisma .carousel-fade .carousel-inner > .item.prev.right,
    .prisma .carousel-fade .carousel-inner > .item.active {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
}
</style>

<template lang="pug">
  #header
    .box-1(:class="{'type-public': isPageCatalogoPublic, 'header-pedrosa': type_app === 'pedrosa'}")
      .container
        .row
          .col-sm-2
            a(v-if="(type_app === 'prisma' && isPageCatalogoPublic)" href="https://www.prismasl.com" class="logo-cn ")
              img(:src="imgLogo" height="38" alt="")
            router-link(v-else :to="ulrLogo" class="logo-cn" )
              img(:src="imgLogo" height="38" alt="")
          .cliente-box(v-if="!isPageCatalogoPublic" :class="[{'disabled':user && user.clients_count === 1, 'router-link-no-active': $route.name ==='clientList'}, styleColClient]")
                div(v-if="client")
                  router-link(v-if="user && user.clients_count !== 1" :to="{ name: 'clientList'}" class="hit-cn")
                  div
                    small.tw-flex
                      img(:src="img.flecha" width="10")
                      translate Cliente
                    strong  {{nameClient}}
                  img(:src="img.close" width="8" class="close-btn" @click="removeClient()")
                div.empty-cn(v-else)
                  router-link.tw-flex( :to="{ name: 'clientList'}")
                    img(:src="img.cliente" width="18")
                    translate Seleccionar un cliente
          .center-box.col-sm-4(v-if="client && !isPageCatalogoPublic && !$route.path.includes('/estadisticas/')")
            .center-info(:class="{'group-cn': purchase_limit > 0}" data-toggle="modal" data-target="#modalCenter" @click="openModal()")
              div(v-if="center")
                small.tw-flex.tw-gap-1
                  img(:src="img.flecha" width="10")
                  translate Centro
                strong  {{nameCenter}}
              div.empty-cn.tw-flex(v-else)
                img(:src="img.cliente" width="18")
                translate Seleccionar un centro
            .center-bar(:class="{ 'hidden' : !center, 'group-cn': 'purchase_limit > 0'}" v-if="purchase_limit > 0")
              p.limite-cn
                translate Límite:
                span.limit-js &nbsp;{{purchase_limit | format-money}}€
              p.consumo-cn
                translate Consumo
                span.porcen-js {{purchase_limit_percent}}%:
                span.consumo-js {{purchase_current | format-money}}€
              p.bar-cn
                span.porcent-bg
                span.porcent-bar(:class="classBarConsumo" :style="widthBarConsumo")
            button(v-if="center" class="close-btn" @click="removeCenter()")
              img(:src="img.close" width="8")
          .col-sm-1.min-cn.country-box(v-if="user && countries.length > 1 && !isPageCatalogoPublic")
              p(v-if="country")
                img(:src="imgCountry(country.id)" width="18" class="flag")
                span.name {{country.label}}
              ul.country
                li(v-for="item in countries" :key="item.id" :class="{'current' : country && item.id === country.id}" @click="setCountry(item)")
                  img(:src="imgCountry(item.id)" width="14" class="flag")
                  span {{item.label}}

          .col-sm-1.min-cn.user-box(v-if="user && !isPageCatalogoPublic")
            p.tw-flex.tw-justify-center.tw-items-center.tw-flex-col
              img(:src="img.micuenta" width="18")
              span.name #[translate Mi cuenta]
            ul.user
              li
                router-link.tw-flex.tw-gap-2( :to="{ name: 'perfil'}" )
                  img(:src="img.config" width="14")
                  translate Configuración
              li
                router-link.tw-flex.tw-gap-2( :to="{ name: 'helpmessage'}" )
                  img(:src="img.ayuda" width="18")
                  translate Ayuda
              li
                a.tw-flex.tw-gap-2(href="#" @click="logout")
                  img(:src="img.salir" width="14")
                  translate Salir

          .col-sm-1.min-cn.favorite-box(v-if="isFavorite")
            p
              router-link( :to="{ name: 'favoritesList'}" )
                span( class="ico-favorite")
                  i(v-if="favoriteCount" :data-item="favoriteCount")
                  img(:src="img.favorite" width="22")
                span.name #[translate Favorite]


          .min-cn.cart-cn(v-if="user && !isPageCatalogoPublic" :class="{'col-sm-2': !isFavorite, 'col-sm-1': isFavorite}")
            bk-cart

    .box-2.container(v-if="!isPageCatalogoPublic")
        .row
          .col-sm-12
            ul.nav
              // li
                router-link( :to="{ name: 'clientList'}" :class="{'router-link-no-active': $route.name ==='catalogoList'}" ) #[translate Clientes]
              li
                router-link( :to="{ name: 'catalogoList'}" ) #[translate Productos]
              li(v-if="config_app.menu_pedidos_home && !buyerCookie" class="nav-pedidos")
                router-link( :to="{ name: 'pedidosHome'}" ) #[translate Pedidos]
                  i(v-if="validable_orders")
              li(v-if="config_app.catalog_generator && role !== 'client' && type_app !== 'pedrosa' && !buyerCookie")
                router-link( :to="{ name: 'catalogGeneratorList'}" ) #[translate Catálogos]
              li(v-if="config_app.catalog_generator && role !== 'client' && type_app === 'pedrosa' && !buyerCookie")
                router-link( :to="{ name: 'catalogGeneratorList'}" ) #[translate Ofertas]
              li(v-if="client && !buyerCookie")
                router-link( :to="{ name: 'estadisticas'}") #[translate Estadísticas]
              li( v-if=" (type_app === 'prisma' || type_app === 'vario') && role !== 'client' && !buyerCookie")
                router-link( :to="{ name: 'leadsList'}" ) #[translate Leads]
              li( v-if=" (type_app === 'prisma' || type_app === 'vario') && role !== 'client' && !buyerCookie" class="nav-ofertas")
                router-link( :to="{ name: 'ofertasList'}" ) #[translate Ofertas]
                  i(v-if="validable_offers")
              li( v-if=" type_app === 'prisma' && role !== 'client' && !buyerCookie")
                router-link( :to="{ name: 'dashboard'}" ) #[translate Dashboard]
              // li
                // router-link( :to="{ name: 'informesList'}" ) #[translate Informes]
              li( v-if=" (hasClientOrCenter && appConfig.featureFlags.carbonFootprint) ")
                router-link( :to="{ name: 'carbonFootprint'}" ) #[translate Huella de Carbono]

    transition(name="animate-css" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in")
      .box-3
        .container(v-show="$route.path.includes('/pedidos/')")
          .row
            .col-sm-12
              ul.nav
                li
                  router-link( :to="{ name: 'pedidosComprar'}" ) #[translate Comprar]
                li
                  router-link( :to="{ name: 'pedidosList'}" ) #[translate Mis Pedidos]
                li(v-if="config_app.menu_pedidos_validar")
                  router-link( :to="{ name: 'validarPedidos'}" ) #[translate Validar Pedidos] #[i(v-if="validable_orders") {{validable_orders}}]
                li(v-if="config_app.menu_pedidos_albaranes")
                  router-link( :to="{ name: 'albaranesList'}" ) #[translate Albaranes]
                li(v-if="config_app.menu_pedidos_facturas")
                  router-link( :to="{ name: 'facturasList'}" ) #[translate Facturas]
                li(v-if="config_app.menu_pedidos_importacion")
                  router-link( :to="{ name: 'importacionPedidos'}" ) #[translate Importación]

        .container(v-show="$route.path.includes('/ofertas/') && config_app.menu_ofertas_validar")
          .row
            .col-sm-12
              ul.nav
                li
                  router-link( :to="{ name: 'ofertasList'}" ) #[translate Ofertas]
                li
                  router-link( :to="{ name: 'validarOfertas'}" ) #[translate Quotation Workflow] #[i(v-if="validable_offers") {{validable_offers}}]

        .container(v-show="$route.path.includes('/estadisticas/')")
          .row
            .col-sm-12
              ul.nav
                li
                  router-link( :to="{ name: 'estadisticasCompras'}" ) #[translate Compras]
                li
                  router-link( :to="{ name: 'estadisticasCentros'}" ) #[translate Centros]
                li
                  router-link( :to="{ name: 'estadisticasCategorias'}" ) #[translate Categorías]
                li
                  router-link( :to="{ name: 'estadisticasArticulos'}" ) #[translate Artículos]

        .container(v-show="$route.path.includes('/dashboard/')")
          .row
            .col-sm-12
              ul.nav
                li
                  router-link( :to="{ name: 'dashboardResumen'}" ) #[translate Resumen]
                li
                  router-link( :to="{ name: 'dashboardPedidos'}" ) #[translate Pedidos]
                li
                  router-link( :to="{ name: 'dashboardAlbaranes'}" ) #[translate Albaranes]
                li
                  router-link( :to="{ name: 'dashboardVentas'}" ) #[translate Ventas]
                li
                  router-link( :to="{ name: 'dashboardProveedores'}" ) #[translate Proveedores]
                //li
                  router-link( :to="{ name: 'dashboardOfertas'}" ) #[translate Nivel de Prospección]

    bk-modal-center(ref="modalCenter")
    bk-modal(ref="modalCommon" id="modal-common-header" :title="modal.title" :content="modal.content" :footer="modal.footer")
    bk-modal-info-soste(ref="modalInfoSoste" id="modal-common-info-soste")

    p.hidden
      button(data-toggle="modal" data-target="#modal-common-info-soste" class="btn-modal" ref="showModalInfoSoste")

      button(data-toggle="modal" data-target="#modal-common" class="btn-modal" ref="showModal")
</template>
<script>
import {mapGetters} from 'vuex'
import BkModal from '@/components/common/Modal.vue'
import BkModalCenter from '@/components/center/Model-content.vue'
import BkCart from '@/components/common/Cart.vue'
import appConfig from '@/config-app.js'
import Stores from "@/store";
import BkModalInfoSoste from '@/components/common/Modal-info-soste.vue'

export default {
  name: 'Header',
  components: {BkModal, BkCart, BkModalCenter, BkModalInfoSoste},
  data () {
    return {
      appConfig: appConfig,
      img: {
        close: require('@/assets/img/icos/cerrar.svg'),
        flecha: require('@/assets/img/icos/flecha-01.svg'),
        cliente: require('@/assets/img/icos/cliente.svg'),
        ayuda: require('@/assets/img/icos/ayuda.svg'),
        micuenta: require('@/assets/img/icos/micuenta.svg'),
        favorite: require('@/assets/img/icos/favorite-head.svg'),
        eu: require('@/assets/img/icos/pais.svg'),
        fi: require('@/assets/img/icos/pais-finlandia.svg'),
        it: require('@/assets/img/icos/pais-italia.svg'),
        lv: require('@/assets/img/icos/pais-letonia.svg'),
        ro: require('@/assets/img/icos/pais-rumania.svg'),
        pt: require('@/assets/img/icos/pais-portugal.svg'),
        sp: require('@/assets/img/icos/pais-spain.svg'),
        config: require('@/assets/img/icos/config.svg'),
        salir: require('@/assets/img/icos/salir.svg')
      },
      modal: {
        // show: false,
        title: '',
        content: '',
        footer: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'role', 'countries'
    ]),
    buyerCookie () {
      const buyerCookie = window.localStorage.getItem('_buyerCookie')
      return buyerCookie
    },
    imgLogo () {
      const extension = (this.type_app === 'inpacs' || this.type_app === 'vario') ? 'svg' : 'png'
      return require(`@/assets/img/themes/${this.$config.type}/logo-head.${extension}`)
    },
    ulrLogo () {
      if (this.config_app.catalogo_public && (this.$route.name === 'catalogoListPublic' || this.$route.name === 'productSinglePublic')) {
        return {name: 'catalogoListPublic'}
      }

      return {name: this.config_app.url_home}
    },
    styleColClient () {
      if (this.client && !this.$route.path.includes('/estadisticas/') && this.countries.length > 1) {
        return 'col-sm-2'
      }

      if (this.client && !this.$route.path.includes('/estadisticas/') && this.countries.length < 2) {
        return 'col-sm-3'
      }

      if ((!this.client || this.$route.path.includes('/estadisticas/')) && this.countries.length > 1) {
        return 'col-sm-6'
      }

      if ((!this.client || this.$route.path.includes('/estadisticas/')) && this.countries.length < 2) {
        return 'col-sm-7'
      }
    },
    validable_orders () {
      return this.$store.getters.userInfo.validable_orders
    },
    validable_offers () {
      return this.$store.getters.userInfo.validable_offers
    },
    country () {
      let item = this.$store.getters.userInfo.country
      if (item === null && this.countries.length > 0) {
        this.setCountry(this.countries[0])
      }
      return this.$store.getters.userInfo.country
    },
    user () {
      return this.$store.getters.user
    },
    client () {
      return this.$store.getters.client
    },
    nameClient () {
      if (this.client) {
        return this.client.name
      } else {
        return '-'
      }
    },
    center () {
      return this.$store.getters.center
    },
    nameCenter () {
      if (this.center) {
        return this.center.name
      }
      return '-'
    },
    purchase_limit () {
      // límite - cuando purchase_limit= '0' no tienes que mostrar nada.
      if (this.center) {
        const limit = this.center.purchase_limit // this.$options.filters.formatMoney(this.center.purchase_limit)
        return limit
      }
      return 0
    },
    purchase_limit_percent () {
      // porcentaje del límite
      if (this.center) {
        return this.center.purchase_limit_percent
      }
      return 0
    },
    purchase_current () {
      if (this.center) {
        return this.center.purchase_current
      }
      return 0
    },
    classBarConsumo () {
      if (this.purchase_limit_percent) {
        if (this.purchase_limit_percent < 70) {
          return 'min'
        } else if (this.purchase_limit_percent >= 70 && this.purchase_limit_percent <= 90) {
          return 'medium'
        } else {
          return 'max'
        }
      }
    },
    widthBarConsumo () {
      if (this.purchase_limit_percent) {
        return `width:${this.purchase_limit_percent}%;`
      }
      return 'width:0%;'
    },
    hasClientOrCenter () {
      const oClient = Stores.state.client.client
      const oCenter = Stores.state.center.center
      return !!oClient || !!oCenter
    },
    type_app () {
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    isPageCatalogoPublic () {
      if (
        this.config_app.catalogo_public &&
        (
          this.$route.name === 'catalogoListPublic' ||
          this.$route.name === 'productSinglePublic' ||
          this.$route.name === 'catalogoPublicSearch'
        )
      ) {
        return true
      }

      return false
    },
    isFavorite () {
      return this.config_app.favorite
    },
    favoriteCount () {
      return this.$store.getters.favoritesCount
    },
    favoritesItems () {
      return this.$store.getters.favoritesItems
    },
    addItemCart () {
      return this.$store.getters.addItemCart
    },
    modalInfoSoste () {
      return this.$store.getters.getShowModalInfoSoste
    }
  },
  created () {
    // console.log('--', this.config_app.menu_albaranes)
    // Cogemos los datos del carrito
    this.$store.dispatch('getCart')
    // Cogemos los datos de los favoritos.
    this.$store.dispatch('getAllFavorite')

    this.$store.dispatch('updateValidableOrders')
    // actualizamos el valor de las ofertas pendientes.
    this.$store.dispatch('updateValidableOffers')
    // add favicon prisma
    if (this.$config.type === 'prisma') {
      let setFavicon = document.createElement('link')
      setFavicon.setAttribute('rel', 'shortcut icon')
      setFavicon.setAttribute('href', '/img/icons/favicon-16x16.png')
      document.head.appendChild(setFavicon)
    }
    // añadir código de analytics.
    if (this.config_app.id_ga) {
      const script = document.createElement('script')
      script.src = `https://www.googletagmanager.com/gtag/js?id=${this.config_app.id_ga}`
      script.async = true
      document.head.appendChild(script)

      const scriptLine = document.createElement('script')
      // scriptLine.type = 'text/javascript'
      scriptLine.text = `window.dataLayer = window.dataLayer || [];
                         function gtag(){dataLayer.push(arguments);}
                         gtag('js', new Date()); gtag('config', '${this.config_app.id_ga}');`

      document.head.appendChild(scriptLine)
    }

    // SI ES ORBISHIGIENE BORRAMOS FRESHCHAT SI EXISTE
    if (this.type_app === 'orbishigiene' && !document.querySelector('#ze-snippet')) {
      const script = document.createElement('script')
      script.id = 'ze-snippet'
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=e6086d79-a002-470d-8e8f-5b6b43d1c25a`
      document.head.appendChild(script)
    }
  },
  methods: {
    openModal () {
      this.$refs.modalCenter.openModal()
    },
    imgCountry (id) {
      return this.img[id]
    },
    setCountry (item) {
      this.$store.dispatch('updateCountry', item)
      // this.$router.push({'name': 'clientList'})
    },
    removeClient () {
      // console.log('removeClient')
      this.$store.dispatch('removeClient')
    },
    removeCenter () {
      // console.log('removeCenter')
      this.$store.dispatch('removeCenter')
    },
    logout () {
      // console.log('logout')
      this.$store.dispatch('deleteCartLogut')
      this.$store.dispatch('logout')
      this.$router.push('/')
    },
    showModalMsg (msg) {
      const labelBtn = this.$gettext('Aceptar')
      this.modal.content = `<p style="text-align:center;padding-top:20px;">${msg}</p>
                            <p style="text-align:center;padding:20px 0 10px;"><button type="button" data-dismiss="modal" class="btn btn-action">${labelBtn}</button></p>`
      // En el HTML hay un botón con el atributo ref="showModal" y de esta forma le hacemos click para mostrar la modal.
      this.$refs.showModal.click()
    },
    showModalInfoSoste () {
      this.$refs.showModalInfoSoste.click()
    }
  },
  watch: {
    client () {
      // actualizamos el valor de los pedidos pendientes.
      this.$store.dispatch('updateValidableOrders')
      // actualizamos el valor de las ofertas pendientes.
      this.$store.dispatch('updateValidableOffers')
    },
    center () {
      // actualizamos el valor de los pedidos pendientes.
      this.$store.dispatch('updateValidableOrders')
      // actualizamos el valor de las ofertas pendientes.
      this.$store.dispatch('updateValidableOffers')
    },
    addItemCart (newValue, oldValue) {
      // console.log('watch addItemCart --> newValue: ', newValue)

      // TODO: Para mostrar el popup si el producto añadido es del tipo 'on order'
      /* const msg = this.$gettext('The product is on order.<br>No changes are allowed after order confirmation')
      this.showModalMsg(msg) */
    },
    modalInfoSoste (newValue) {
      if (newValue) {
        this.showModalInfoSoste()
      }
    }
  }
}
</script>
<style lang="scss" scoped>

  #header{
    // position: relative;
    // z-index: 2;
    background-color: $white;
  }

  .logo-cn{
    width: 100%;
    max-width: 160px;
    img{
      max-height: 38px;
    }
  }

  .box-1{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 12;
    background-color: #1C2C40;
    @include bgColor50(#1C2C40, #EBF3FE);
    color:$white;

    & > .container{
      width: 1280px;
    }
    .row > *{
      height: 60px;
      display: flex;
      align-items: center;
    }

    &.type-public{
      background: #1C2C40;
      &.header-pedrosa{
        display: none;
      }
    }
  }

  .cliente-box, .center-box{
    position: relative;
    background-color: #89ACE3;
    padding: 0 24px 0 15px;
    display: block;

    &.disabled{
      small {
        visibility: hidden;
      }
      .close-btn {
        display: none;
      }
    }

    & > *{
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    a{
      color: $white;
      display: flex;
      &:hover, &:focus{
        text-decoration: none;
      }
    }

    .hit-cn{
      position: absolute;
      z-index: 1;
      top:0;
      bottom: 0;
      left:0;
      right:0;
    }

    small{
      font-size: 13px;
      margin-bottom: 2px;

      img{
        margin-right: 6px;
        vertical-align: baseline;
      }
    }

    strong{
      font-weight: $regular;
      font-size: 14px;
      color: #1C2C40;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    .close-btn{
      position: absolute;
      top:8px;
      right: 8px;
      background-color: transparent;
      border:0;
      padding:0;
      line-height: 0;
      cursor: pointer;
      z-index: 1;
    }

    .empty-cn{
      font-size: 14px;
      line-height: 24px;
      img{
        margin-right: 10px;
      }
    }
  }

  .cliente-box, .center-box{
    &.router-link-no-active{
      background-color: #cfcfcf;

      a{
        cursor: default;
      }
    }
  }

  .center-box{
    background-color: #ABC7F1;
    cursor: pointer;

    .center-info, .center-bar{
      &.group-cn{
        width: 50%;
      }
    }

    .center-info{
      padding-right: 10px;
    }

    .center-bar{
      font-size: 12px;
      color: #1C2C40;

      p{
        margin-bottom: 0;
      }
    }
  }

  .consumo-cn{
    span:not(:first-child){
      margin-left: 4px;
    }
  }

  .bar-cn{
    // display:none;
    position: relative;
    display: block;
    max-width: 200px;
    height: 6px;
    margin-top: 2px;
    overflow: hidden;
    @include roundAll(4);

    span{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;

      &.porcent-bg{
        width: 100%;
        background-color: #dce5e8;
      }

      &.porcent-bar{
        width: 0%;

        &.min{
          background-color: #99c46c;
        }

        &.medium{
            background-color: #F7B633;
        }

        &.max{
            background-color: #ff8b61;
        }
      }
    }
  }

  .min-cn{
    position: relative;
    background-color: #EBF3FE;
    color:#333333;
    justify-content: center;
    cursor:pointer;

    .name{
      @include transition();
    }

    &.country-box{
      background-color: #CBE0FF;
    }

    &.user-box, &.favorite-box{
      background-color: #E1EDFE;
    }

    &:hover, &:focus{
      .name{
        color: $blue-light;
      }
      ul{
        display:block;
      }
    }

    .flag{
      background-color: $white;
      @include roundAll100(50%);
    }

    p{
      margin:0;
      text-align: center;
      img{
        font-size: 18px;
      }
      span{
        font-size: 12px;
        display: block;
      }
    }

    ul{
      display: none;
      position:absolute;
      z-index: -1;
      top: 50px;
      left: 0px;
      width: 120px;
      padding: 10px 0 0;
      z-index: -1;
      background-color: $white;
      -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
      -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
      box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    }

    li{
      font-size: 12px;
      display: block;
      padding: 8px;
      @include transition();
      // line-height: 18px;
      img{
        margin-right: 6px;
      }
      span{
        display: inline-block;
        vertical-align: text-top;
      }
      &.current, &:hover, &:focus{
        background-color: #EBF3FE;
      }
    }

    a{
      color: $black;

      &:hover, &:focus{
        text-decoration: none;
      }
    }
  }

  .favorite-box{

  }

  .cart-cn{
    padding:0;
  }

  .nav{
    padding-top: 20px;

    li{
      margin:0 14px;
      font-size: 17px;
      font-weight: $light;
      display:inline-block;

      &:first-child{
        margin-left:0;
      }

      i{
        position: relative;
        top: -8px;
        right: 0px;
        @include roundAll(20);
        padding: 3px 6px;
        background-color: #fa9f34;
        color: #fff;
        font-size: 9px;
        vertical-align: text-top;
        font-style: normal;
        font-weight: $regular;
      }

      &.nav-pedidos, &.nav-ofertas{
        i{
          top: -4px;
          right: -2px;
          padding: 0;
          width: 9px;
          height: 9px;
          display: inline-block;
        }
      }
    }

    a{
      position:relative;
      color:#1C2C40;
      padding: 0 10px 8px;

      img{
        position: relative;
        top: -6px;
        right: 4px;
        @include roundAll(20);
        padding:3px 6px;
        background-color: $orange-alert;
        color: $white;
        font-size: 12px;
        vertical-align: text-top;
        font-style: normal;
      }

      &:before{
        content:'';
        position:absolute;
        bottom:0;
        left:0;
        right:0;
        margin:0 auto;
        width:0%;
        height:3px;
        @include transition();
      }

      &:hover, &:focus{
        background-color: transparent;

        &:before{
          width:100%;
          background-color: rgba($black, 0.2);
        }
      }

      &.router-link-active{
        font-weight: $medium;
        &:before{
          width:100%;
          background-color: $blue-light;
        }
      }

      &.router-link-no-active{
        &:before{
          display: none;
        }
      }
    }
  }

  .box-2{
    margin-top: 60px;
  }

  .box-3{
    background-color: rgba(#EFEFEF, .33);

    .nav{
      padding-top: 14px;
      li{
        font-size: 14px;
      }
    }
  }

  .col-last{
    padding-left:0;
    padding-right:0;
  }

  .ico-favorite{
      position:relative;

      i{
        content: attr(data-item);
        position: absolute;
        top: -4px;
        right: -10px;
        font-family: $font;
        font-size: 10px;
        line-height: 10px;
        min-width: 22px;
        min-height: 22px;
        text-align: center;
        padding: 6px;
        background-color: #89ACE3;
        color: $white;
        @include roundAll100(50%);
        @include transition();

        &:after{
          content: attr(data-item);
          font-style: normal;
        }
      }
  }
</style>

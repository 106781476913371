import { render, staticRenderFns } from "./OfertasValidar.vue?vue&type=template&id=1d67c57c&scoped=true&lang=pug&"
import script from "./OfertasValidar.vue?vue&type=script&lang=js&"
export * from "./OfertasValidar.vue?vue&type=script&lang=js&"
import style0 from "./OfertasValidar.vue?vue&type=style&index=0&id=1d67c57c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d67c57c",
  null
  
)

export default component.exports